import axios from 'axios'

const axiosHelper = (url, params, body, method, headers, type = 'json') => {
  return new Promise((resolve, reject) => {
    const axiosMeta = {
      url: url,
      method: method,
      params: params,
      data: body,
      headers: headers,
      type: type,
    }

    // Make the API Call
    axios(axiosMeta)
      .then(result => resolve(result.data))
      .catch(error => {
        if (error.response && error.response.data) reject(error.response.data)
        else reject({ 'error': 'Server error.' })
      })
  })
}

export default axiosHelper
