import * as api from '../helper/api'

/* retrieve all roles from db */
export async function getRoles() {
  try {
    const { data } = await api.get('roles', {})
    return data
  } catch (err) {
    return undefined
  }
}

/* create a new role with permissions */
export async function create(dto) {
  try {
    const { data } = await api.post('roles', dto)
    return data
  } catch (error) {
    if (error.response) {
      const { data = undefined } = error.response || {}
      if (data) {
        throw error
      }
    }
  }
}

/* update role's name/permissions */
export async function update(id, dto) {
  try {
    const { data } = await api.put(`roles/${id}`, dto)
    return data
  } catch (error) {
    if (error.response) {
      const { data = undefined } = error.response || {}
      if (data) {
        throw error
      }
    }
  }
}

/* Delete role */
export async function remove(id) {
  try {
    await api.remove(`roles/${id}`)
    return true
  } catch (error) {
    return false
  }
}

/* Get all permission from db */
export async function getPermissions() {
  try {
    const { data } = await api.get(`permissions`, {})
    return data
  } catch (error) {
    return undefined
  }
}
