const docqParentDetailsModule = {
  namespace: true,
  state: () => ({
    docqParent: null,
    promise: Promise.resolve(),
  }),
  /* functions to return these data from state */
  getters: {
    read_parent: state => {
      return state.parent
    },
  },
  /* functions to update these data of state (sync functions)*/
  mutations: {
    loaded(state, curParent) {
      state.docqParent = curParent
    },
  },
}

export default docqParentDetailsModule
