import { createApp } from 'vue'
import App from './App.vue'
import routes from './routes'
import common from './helper/common'
import store from './store'
import { setupAuth } from './config/auth'
import * as api from './helper/api'
import * as services from './services'

import ElementPlus from 'element-plus'

import './assets/scss/app.scss'
import '../src/assets/js/app'

const app = createApp(App)
app.use(store)
app.use(routes)
app.use(ElementPlus)

app.config.globalProperties.$common = common
app.config.globalProperties.$api = api
app.config.globalProperties.$services = services

const auth_options = {
  'domain': process.env.VUE_APP_AUTH_DOMAIN,
  'client_id': process.env.VUE_APP_AUTH_CLIENTID,
  'redirect_uri': location.origin,
  'cacheLocation': 'localstorage',
  'useRefreshTokens': true,
}

setupAuth(auth_options).then(auth => {
  app.use(auth)
  app.mount('#app')
})
