import * as api from '../helper/api'

/* get All DocQ-Instance Parents */
export async function getParentList() {
  try {
    const { data } = await api.get('docq-instances/parents')
    return data
  } catch (error) {
    return error
  }
}

/* get One DocQ-Instance Parent by id with its all children */
export async function getParent(id) {
  try {
    const { data } = await api.get(`docq-instances/parents/${id}`)
    return data
  } catch (error) {
    return undefined
  }
}

/* update children instance info for the parent DocQ-Instance */
export async function updateParent(id, remoteDatabase) {
  try {
    const { data } = await api.put(`docq-instances/parents/${id}`, {
      remote_database: {
        database: remoteDatabase.database,
        host: remoteDatabase.host,
        port: remoteDatabase.port,
        user: remoteDatabase.user,
        password: remoteDatabase.password,
      },
    })
    return data
  } catch (error) {
    console.log(error)
    return null
  }
}

/* delete the DocQ-Instance Parent and clean up the remote database */
export async function removeParent(id) {
  try {
    const { data } = await api.remove(`docq-instances/parents/${id}`)
    return data
  } catch (error) {
    return error
  }
}

/* test remote database connection when create new parent */
export async function testConnection(remoteDatabase) {
  try {
    await api.post('docq-instances/parents/test', {
      database: remoteDatabase.database,
      host: remoteDatabase.host,
      port: remoteDatabase.port,
      user: remoteDatabase.user,
      password: remoteDatabase.password,
    })
    return true
  } catch (error) {
    return false
  }
}

/* Store relation by Usernames */
/* The endpoints will return error message if data validation is wrong */
/* After validation, any error during the process(ex. create user or connection error), will be store in db*/
export async function storeRelationByUsername(id, user, domains) {
  try {
    await api.put(`docq-instances/parents/${id}/usernames`, {
      'username': user,
      'domains': domains,
    })
  } catch (error) {
    console.log(error.response.data.data[0].error)
    return error.response.data
  }
}

/* Store relation by domains */
/* The endpoints will return error message if data validation is wrong */
/* After validation, any error during the process(ex. create user or connection error), will be store in db*/
export async function storeRelationByDomain(id, domain, users) {
  try {
    await api.put(`docq-instances/parents/${id}/domains`, {
      'domain': domain,
      'usernames': users,
    })
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

/* fetch all users of a parent by instance_id */
export async function getUsers(id) {
  try {
    const { data } = await api.get(`docq-instances/parents/${id}/usernames`)
    return data
  } catch (error) {
    return null
  }
}

/* fetch all non-parent instance */
export async function getParentAvailability() {
  try {
    const { data } = await api.get('docq-instances/parents/availability')
    return data
  } catch (error) {
    return null
  }
}
