import { get } from '@/services/docqDocumentCounts'
import common from '@/helper/common'

const TIMEOUT = 900

const dashboardCountResultsModule = {
  namespaced: true,

  state: () => ({
    timestamp: null,
    docCountsResult: null,
    loading: false,
    promise: Promise.resolve(),
  }),
  /* functions to return these data from state */
  getters: {
    count_result: state => {
      return state.docCountsResult
    },
  },
  /* functions to update these data of state (sync functions)*/
  mutations: {
    loaded(state, response) {
      state.timestamp = new Date()
      state.docCountsResult = response
    },
  },
  /* call api to load data from backend (async functions)*/
  actions: {
    async loadData({ commit, state }) {
      const isExpired = common.isDateExpired(state.timestamp, TIMEOUT)
      if (isExpired || !state.docCountsResult) {
        // call api to fetch data, return it and store it
        const response = await get()
        commit('loaded', response)
      }
    },
  },
}

export default dashboardCountResultsModule
