<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row text-muted">
        <div class="col-6 text-start">
          <p class="mb-0">
            <strong>NDM Global</strong>
            &copy;
          </p>
        </div>
        <div class="col-6 text-end">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a class="text-muted" href="https://wiki.docq.app/doku.php" target="_blank"
                >Documentation</a
              >
            </li>
            <li class="list-inline-item">
              <a class="text-muted" href="https://docq.app/privacy-policy/" target="_blank"
                >Privacy</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
  }
</script>

<style scoped></style>
