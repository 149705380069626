import { getUsers, validate } from '@/services/users'
import { getRoles, getPermissions } from '@/services/roles'

const roleManagementModule = {
  namespaced: true,

  state: () => ({
    usersData: null,
    rolesData: null,
    permissionsData: null,
    isUserRoleMode: false,
    managePermissions: false,
    promise: Promise.resolve(),
  }),

  /* functions to return these data from state */
  getters: {
    users_data: state => {
      return state.usersData
    },
    roles_data: state => {
      return state.rolesData
    },
    permissions_data: state => {
      return state.permissionsData
    },
    current_mode: state => {
      return state.isUserRoleMode
    },
    manage_permission_validation: state => {
      return state.managePermissions
    },
  },
  /* functions to update these data of state (sync functions)*/
  mutations: {
    usersLoaded(state, response) {
      state.usersData = response
    },
    rolesLoaded(state, response) {
      state.rolesData = response
    },
    permissionsLoaded(state, response) {
      state.permissionsData = response
    },
    changeMode(state) {
      state.isUserRoleMode = !state.isUserRoleMode
    },
    managePermissionsValidated(state, response) {
      state.managePermissions = response
    },
  },
  /* call api to load data from backend (async functions)*/
  actions: {
    async loadUsersData({ commit }) {
      // call api to fetch data, return it and store it
      const response = await getUsers()
      commit('usersLoaded', response)
    },
    async loadRolesData({ commit }) {
      // call api to fetch data, return it and store it
      const response = await getRoles()
      commit('rolesLoaded', response)
    },
    async loadPermissionsData({ commit }) {
      // call api to fetch data, return it and store it
      const response = await getPermissions()
      commit('permissionsLoaded', response)
    },
    async validateManagePermissions({ commit }) {
      const response = await validate('MANAGE_PERMISSIONS')
      commit('managePermissionsValidated', response)
    },
  },
}

export default roleManagementModule
