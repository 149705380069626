import axios from 'axios'
import axiosHelper from './axios_helper'
import auth from '../config/auth'

const BASE_URL = process.env.VUE_APP_API
function createHeaders() {
  const headers = []
  headers.push({ 'Content-Type': 'application/json' })
  if (auth.token.value) headers.push({ 'Authorization': `bearer ${auth.token.value}` })

  return Object.assign({}, ...headers)
}

export function get(endpoint, params = {}) {
  const headers = createHeaders()
  return axios(`${BASE_URL}/${endpoint}`, { headers, params })
}

export function get2(endpoint, params = {}) {
  const headers = createHeaders()
  return axios(`${BASE_URL}/${endpoint}`, { headers, params })
}

export function post(endpoint, data) {
  const headers = createHeaders()
  return axios.post(`${BASE_URL}/${endpoint}`, data, { headers })
}

export function put(endpoint, data) {
  const headers = createHeaders()
  return axios.put(`${BASE_URL}/${endpoint}`, data, { headers })
}

export function patch(endpoint, data) {
  const headers = createHeaders()
  return axios.patch(`${BASE_URL}/${endpoint}`, data, { headers })
}

export function remove(endpoint) {
  const headers = createHeaders()
  return axios.delete(`${BASE_URL}/${endpoint}`, { headers })
}

export function uploadImage(instance_data, _type, file) {
  const url = `${BASE_URL}/docq-instances/${instance_data.id}/upload-image`
  const params = { 'type': _type }

  const payload = file

  return axiosHelper(url, params, payload, 'POST', createHeaders())
}
