import * as api from '../helper/api'

/* get DocQ instances reports by query param(optional), if no param will return the overall */
export async function get(query) {
  try {
    const { data } = await api.get(`reports/docq-instances/document-count?${query}`)
    return data
  } catch (err) {
    return false
  }
}

/* get One specific instance report by instance_id */
export async function getByID(id) {
  try {
    const { data } = await api.get(`reports/docq-instances/document-count/${id}`)
    return data
  } catch (err) {
    return false
  }
}
