import * as api from '../helper/api'

/* API for snapshots service */
export async function get() {
  const { data } = await api.get('snapshots')
  return data
}

export async function show(id) {
  try {
    const { data } = await api.get(`snapshots/${id}`)
    return data
  } catch (error) {
    return undefined
  }
}

export async function create(dto) {
  try {
    const { data } = await api.post('snapshots', dto)
    return data
  } catch (error) {
    if (error.response) {
      const { data = undefined } = error.response || {}
      if (data) {
        throw error
      }
    }
  }
}

export async function update(id, data) {
  try {
    await api.put(`snapshots/${id}`, data)
  } catch (error) {
    if (error.response) {
      const { data = undefined } = error.response || {}
      if (data) {
        throw error
      }
    }
  }
}

export async function release(id) {
  try {
    await api.put(`snapshots/${id}/release`)
  } catch (error) {
    return undefined
  }
}

export async function remove(id) {
  try {
    await api.remove(`snapshots/${id}`)
    return true
  } catch (error) {
    return false
  }
}
