import * as api from '../helper/api'

/* get All DocQ instances info */
export async function get() {
  try {
    const { data } = await api.get('docq-instances')
    return data
  } catch (error) {
    return false
  }
}

/* get a DocQ instance info by instance_id */
export async function show(id) {
  try {
    const { data } = await api.get(`docq-instances/${id}`)
    return data
  } catch (error) {
    return undefined
  }
}

/* destroy a DocQ instance with instance_id */
export async function removeInstance(id) {
  try {
    const { data } = await api.remove(`docq-instances/${id}`)
    return data
  } catch (error) {
    return error
  }
}

/* get All DocQ instances States */
export async function getStateList() {
  try {
    const { data } = await api.get('docq-instances/states')
    return data
  } catch (error) {
    return error
  }
}

/* get One DocQ instances State by instance_id */
export async function getState(id) {
  try {
    const { data } = await api.get(`docq-instances/states/${id}`)
    return data
  } catch (error) {
    return undefined
  }
}

/* get DocQ instance usage data by instance_id */
export async function getInstanceUsage(id) {
  try {
    const { data } = await api.get(`docq-instances/${id}/instance-usage`)
    return data
  } catch (error) {
    return undefined
  }
}

/* Create a new instance */
export async function create(dto) {
  try {
    const { data } = await api.post('docq-instances', dto)
    return data
  } catch (error) {
    if (error.response.data) {
      throw error.response.data
    }

    throw new Error('There was an unknown error creating the instance')
  }
}

/* Apply a status rollback on the selected instance */
export async function rollbackStatus(id) {
  await api.patch(`docq-instances/${id}/rollback-status`)
}
