<template>
  <div class="wrapper" v-if="isAuthenticated">
    <Sidebar />
    <div class="main">
      <Navbar @toggleTheme="toggleTheme" />
      <main class="content p-4">
        <div class="container-fluid p-0">
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </div>
      </main>
      <Footer />
    </div>
  </div>
  <div class="container" id="unauthorized-page" v-else>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
</template>

<script>
  import Navbar from '@/components/Navbar'
  import Sidebar from '@/components/Sidebar'
  import Footer from '@/components/Footer'
  export default {
    name: 'App',
    components: {
      Navbar,
      Sidebar,
      Footer,
    },
    data() {
      return {
        appTheme: '',
        isDarkMode: false,
      }
    },
    computed: {
      curTheme() {
        return this.appTheme
      },
      isAuthenticated() {
        return this.$auth.isAuthenticated.value
      },
      redirectPath() {
        const redirect = this.$route.query.redirect
        return redirect ? redirect : '/'
      },
      appState() {
        return {
          appState: {
            targetUrl: this.redirectPath,
          },
        }
      },
    },
    methods: {
      switchThemeColor() {
        const lightTheme = {
          'id': 'light',
          'name': 'Light',
          'primary': '#3B7DDD',
          'secondary': '#6c757d',
          'success': '#1cbb8c',
          'info': '#17a2b8',
          'warning': '#fcb92c',
          'danger': '#dc3545',
          'white': '#fff',
          'gray-100': '#f8f9fa',
          'gray-200': '#e9ecef',
          'gray-300': '#dee2e6',
          'gray-400': '#ced4da',
          'gray-500': '#adb5bd',
          'gray-600': '#6c757d',
          'gray-700': '#495057',
          'gray-800': '#343a40',
          'gray-900': '#212529',
          'black': '#000',
        }

        const darkTheme = {
          'id': 'dark',
          'name': 'Dark',
          'primary': '#3B7DDD',
          'secondary': '#7a828a',
          'success': '#1cbb8c',
          'info': '#17a2b8',
          'warning': '#fcb92c',
          'danger': '#dc3545',
          'white': '#222E3C',
          'gray-100': '#384350',
          'gray-200': '#4e5863',
          'gray-300': '#646d77',
          'gray-400': '#7a828a',
          'gray-500': '#91979e',
          'gray-600': '#a7abb1',
          'gray-700': '#bdc0c5',
          'gray-800': '#d3d5d8',
          'gray-900': '#e9eaec',
          'black': '#fff',
        }
        // Add theme to the window object
        if (localStorage.getItem('theme') === 'light') {
          window.theme = lightTheme
        } else {
          window.theme = darkTheme
        }
      },
      toggleTheme() {
        this.isDarkMode = !this.isDarkMode
        document.body.classList.toggle('dark-theme')
        localStorage.setItem('theme', this.isDarkMode ? 'dark' : 'light')
        document.body.setAttribute('data-theme', this.isDarkMode ? 'dark' : 'default')
        document.body.setAttribute('theme', this.isDarkMode ? 'dark' : 'default')
      },
      redirect() {
        this.$router.replace(this.redirectPath)
      },
    },
    mounted() {
      if (!this.isAuthenticated && !new URLSearchParams(window.location.search).get('error')) {
        this.$auth.loginWithRedirect(this.appState)
      }
      if (new URLSearchParams(window.location.search).get('error')) {
        this.redirect()
      }
    },
    created() {
      const theme = localStorage.getItem('theme')
      this.appTheme = theme
      if (theme === 'dark') this.toggleTheme()
    },
  }
</script>

<style lang="scss">
  #unauthorized-page {
    padding-top: 35vh;
  }
</style>
