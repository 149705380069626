import * as api from '../helper/api.js'

/* get all configuration logs by instance id (Only return Log_type = "SUPER_USER") */
export async function show(id) {
  try {
    const { data } = await api.get(`docq-instances/configlogs/${id}`)
    return data
  } catch (error) {
    return null
  }
}
