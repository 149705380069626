<template>
  <nav id="sidebar" class="sidebar js-sidebar">
    <div class="sidebar-content js-simplebar">
      <router-link class="sidebar-brand" to="/">
        <img src="@/assets/DocQ_Logo_Circle.png" height="40" width="40" loading="lazy" />
        <span class="align-middle"> DocQ</span>
      </router-link>

      <ul class="sidebar-nav">
        <li class="sidebar-header">
          Dashboard
        </li>

        <li
          v-for="tab in tabs"
          :key="tab"
          class="sidebar-item"
          :class="isActive(tab.path) ? 'active' : ''"
        >
          <router-link class="sidebar-link" :to="tab.path">
            <i :class="tab.icon" style="font-size: 1.2rem"></i>
            <span class="align-middle">{{ tab.name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'Sidebar',
    data() {
      return {
        curTab: null,
        tabs: [
          {
            'name': 'Dashboard',
            'icon': 'align-middle bi bi-grid-fill',
            'path': '/',
          },
          {
            'name': 'Instances',
            'icon': 'align-middle bi bi-hdd-fill',
            'path': '/instances',
          },
          {
            'name': 'Parents',
            'icon': 'align-middle bi bi-people-fill',
            'path': '/parents',
          },
          {
            'name': 'Snapshots',
            'icon': 'align-middle bi bi-bell-fill',
            'path': '/snapshots',
          },
          {
            'name': 'Roles',
            'icon': 'align-middle bi bi-lock-fill',
            'path': '/roles',
          },
        ],
      }
    },
    computed: {
      active() {
        return this.$route.path
      },
    },
    methods: {
      isActive(path) {
        return this.active === path
      },
    },
  }
</script>

<style scoped></style>
