import * as api from '../helper/api'

export async function get_droplets() {
  const { data } = await api.get('digitalocean/droplets')
  return data
}

export async function get_projects() {
  const { data } = await api.get('digitalocean/projects')
  return data
}
