import * as api from '../helper/api'

export async function get() {
  try {
    const { data } = await api.get('modules', {})
    return data
  } catch (error) {
    return error
  }
}

export async function getAll() {
  try {
    const { data } = await api.get('modules/all')
    return data
  } catch (error) {
    return error
  }
}
