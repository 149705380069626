import { createRouter, createWebHistory } from 'vue-router'
import { routeGuard } from '@/config/auth'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: 'login' */ '../views/Login/Login.vue'),
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () =>
      import(/* webpackChunkName: 'login' */ '../views/Unauthorized/Unauthorized.vue'),
  },
  {
    path: '/',
    name: 'Dashboard',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Dashboard/Dashboard.vue'),
  },
  {
    path: '/instances',
    name: 'Instances',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: 'instances' */ '../views/Instances/Instances.vue'),
  },
  {
    path: '/instances/create',
    name: 'Create Instance',
    beforeEnter: routeGuard,
    component: () =>
      import(/* webpackChunkName: 'instances' */ '../views/Instances/InstancesCreate.vue'),
  },
  {
    path: '/instances/update',
    name: 'Update Instances',
    beforeEnter: routeGuard,
    component: () =>
      import(/* webpackChunkName: 'instances' */ '../views/Instances/InstancesUpdate.vue'),
  },
  {
    path: '/instances/details/:id',
    name: 'Instance Details',
    beforeEnter: routeGuard,
    component: () =>
      import(/* webpackChunkName: 'instances' */ '../views/Instances/InstancesDetails.vue'),
  },
  {
    path: '/configuration',
    name: 'Configuration',
    beforeEnter: routeGuard,
    component: () =>
      import(/* webpackChunkName: 'configuration' */ '../views/Configuration/Configuration.vue'),
  },
  {
    path: '/parents',
    name: 'Parents',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: 'parents' */ '../views/Parents/Parents.vue'),
  },
  {
    path: '/parents/details/:id',
    name: 'Parent Details',
    beforeEnter: routeGuard,
    component: () =>
      import(/* webpackChunkName: 'parents' */ '../views/Parents/ParentsDetails.vue'),
  },
  {
    path: '/snapshots',
    name: 'Snapshots',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: 'snapshots' */ '../views/Snapshots/Snapshots.vue'),
  },
  {
    path: '/snapshots/details/:id',
    name: 'Snapshots Details',
    beforeEnter: routeGuard,
    component: () =>
      import(/* webpackChunkName: 'snapshots' */ '../views/Snapshots/SnapshotsDetails.vue'),
  },
  {
    path: '/snapshots/create',
    name: 'New Snapshots',
    beforeEnter: routeGuard,
    component: () =>
      import(/* webpackChunkName: 'snapshots' */ '../views/Snapshots/SnapshotsCreate.vue'),
  },
  {
    path: '/roles',
    name: 'Roles Management',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: 'rolesManagement'*/ '../views/Roles/Roles.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach(to => (document.title = `${to.name} · NDM Dashboard`))

export default router
