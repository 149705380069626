import createAuth0Client from '@auth0/auth0-spa-js'
import { computed, reactive } from 'vue'
import routes from '../routes'
import * as services from '../services'

let client
const state = reactive({
  loading: true,
  isAuthenticated: false,
  user: {},
  token: null,
  error: null,
  paths: [],
  menus: null,
  test: false,
})

async function loginWithRedirect(o) {
  try {
    await client.loginWithRedirect(o)
  } catch (e) {
    state.error = e
    console.error(e)
    logout(o)
  } finally {
    if (o && o.appState) redirect(o.appState)
    //await setUserData()
  }
}

async function loginWithPopup(o) {
  state.popupOpen = true

  try {
    await client.loginWithPopup(o)
  } catch (e) {
    state.error = e
    console.error(e)
    logout(o)
  } finally {
    state.popupOpen = false
    if (o && o.appState) redirect(o.appState)
    setUserData()
  }
}

function redirect(appState) {
  const path = appState && appState.targetUrl ? appState.targetUrl : '/'

  const query = window.location.search
  if (query.includes('code=') && query.includes('state=')) {
    // Clean the location.search, it will reload the entire app
    // const url = `${window.location.origin}/#${path}`
    const url = `${window.location.origin}`
    window.location.replace(url)
  } else {
    routes.push(path)
  }
}

async function handleRedirectCallback() {
  state.loading = true

  try {
    await client.handleRedirectCallback()
  } catch (e) {
    state.error = e
  } finally {
    await setUserData()
  }
}

function getIdTokenClaims(o) {
  return client.getIdTokenClaims(o)
}

function getTokenSilently(o) {
  return client.getTokenSilently(o)
}

function getTokenWithPopup(o) {
  return client.getTokenWithPopup(o)
}

function logout(o) {
  const appState = Object.assign({}, o, { 'returnTo': location.origin })
  return client.logout(appState)
}

async function setUserData() {
  state.user = await client.getUser()

  const token_claims = await client.getIdTokenClaims()
  state.token = token_claims ? token_claims.__raw : null

  state.isAuthenticated = await client.isAuthenticated()

  state.loading = false

  if (state.token) {
    await services.users.create()
  }
}

const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  token: computed(() => state.token),
  paths: computed(() => state.paths),
  menus: computed(() => state.menus),
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect,
  loginWithPopup,
  logout,
}

// Update the basic or advance Route if we have new Route
// basic: relate to the sidebar menu
// advance: relate to any route inside pages
const advanceRoute = ['/snapshots/create']
export const routeGuard = async (to, from, next) => {
  if (authPlugin.isAuthenticated.value) {
    if (
      advanceRoute.indexOf(to.fullPath) === 0 &&
      !(await services.users.validate('MANAGE_SNAPSHOTS'))
    ) {
      next({ path: '/', query: { 'redirect': to.fullPath } })
    } else {
      next()
    }
  } else {
    // await authPlugin.loginWithRedirect({ appState: { targetUrl: '/' } })
    const params = new URLSearchParams(window.location.search)
    if (params.get('error')) {
      next({ path: '/unauthorized', query: { 'redirect': to.fullPath } })
    } else {
      next({ path: '/login', query: { 'redirect': to.fullPath } })
    }
  }
}

export const setupAuth = async options => {
  client = await createAuth0Client({
    ...options,
  })

  try {
    // If the user is returning to the app after authentication
    const query = window.location.search
    if (query.includes('code=') && query.includes('state=')) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback()

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      // callbackRedirect(appState)
      redirect(appState)
    }
  } catch (e) {
    state.error = e
  } finally {
    // Initialize our internal authentication state
    await setUserData()
  }

  return {
    install: app => {
      app.config.globalProperties.$auth = authPlugin
    },
  }
}
export default authPlugin
