import * as api from '../helper/api'

/* create user in the backend once user pass authentication */
export async function create() {
  try {
    const { data } = await api.post('users', {})
    return data
  } catch (err) {
    return false
  }
}

/* update user's role (Permission) */
export async function update(id, dto) {
  try {
    const { data } = await api.put(`users/${id}`, dto)
    return data
  } catch (error) {
    if (error.response) {
      const { data = undefined } = error.response || {}
      if (data) {
        throw error
      }
    }
  }
}

/* get current user info */
export async function getUsers() {
  try {
    const { data } = await api.get('users', {})
    return data
  } catch (err) {
    return undefined
  }
}

/* User permission validation */
export async function validate(name) {
  try {
    await api.post(`permissions/validate/${name}`, {})
    return true
  } catch (error) {
    return false
  }
}
