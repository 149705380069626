<template>
  <nav class="navbar navbar-expand navbar-light navbar-bg">
    <a class="sidebar-toggle js-sidebar-toggle" v-on:click="closeSidebar">
      <i class="hamburger align-self-center"></i>
    </a>
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav navbar-align">
        <li class="nav-item">
          <button
            class="btn btn-topbar nav-item"
            @click="toggleTheme()"
            title="Toggle dark theme"
            v-if="!isDarkMode"
          >
            <i class="far fa-sun fa-lg"></i>
          </button>
          <button
            class="btn btn-topbar nav-item"
            @click="toggleTheme()"
            title="Toggle dark theme"
            v-else
          >
            <i class="far fa-moon fa-lg"></i>
          </button>
        </li>
        <li class="nav-item dropdown" v-if="isAuthenticated">
          <a
            class="nav-icon dropdown-toggle d-inline-block d-sm-none"
            href="#"
            data-bs-toggle="dropdown"
          >
            <img class="avatar img-fluid rounded me-1" :src="$auth.user.value.picture" alt="" />
          </a>

          <a
            class="nav-link dropdown-toggle d-none d-sm-inline-block"
            data-bs-toggle="dropdown"
            href="#"
          >
            <img class="avatar img-fluid rounded me-1" :src="$auth.user.value.picture" alt="" />
            <span class="text-dark">{{ $auth.user.value.name }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <button class="btn dropdown-item" disabled>
              <i class="far fa-user me-2"></i>Profile
            </button>
            <div class="dropdown-divider"></div>
            <button class="btn dropdown-item" @click="logToken">
              <i class="fas fa-key me-2"></i>Log Token
            </button>
            <div class="dropdown-divider"></div>
            <button class="btn dropdown-item" @click="$auth.logout()">
              <i class="fas fa-sign-out-alt me-2"></i>Log out
            </button>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'Navbar',
    data() {
      return {
        isDarkMode: false,
      }
    },
    methods: {
      toggleTheme() {
        this.isDarkMode = !this.isDarkMode
        document.body.classList.toggle('dark-theme')
        localStorage.setItem('theme', this.isDarkMode ? 'dark' : 'light')
        document.body.setAttribute('data-theme', this.isDarkMode ? 'dark' : 'default')
        document.body.setAttribute('theme', this.isDarkMode ? 'dark' : 'default')
        this.$emit('toggleTheme')
      },
      logToken() {
        console.log(this.$auth.token.value)
      },
      closeSidebar() {
        document.getElementsByClassName('js-sidebar')[0].classList.toggle('collapsed')
      },
    },
    computed: {
      isAuthenticated() {
        return this.$auth.isAuthenticated.value
      },
    },
    mounted() {
      if (localStorage.getItem('theme') === 'dark') this.isDarkMode = true
    },
  }
</script>

<style scoped lang="scss"></style>
