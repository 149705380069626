function search(str, obj, fields) {
  const _str = str.toLowerCase()
  for (const field of fields) {
    // Ignore non-string
    if (typeof obj[field] != 'string') continue
    const pos = obj[field].toLowerCase().search(_str)
    if (pos >= 0) return true
  }
  return false
}

function isDateExpired(date, seconds) {
  if (!date) return true

  const diff = new Date().getTime() - date.getTime()
  return diff > seconds * 1000
}

function convertTime(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1) // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
    time[0] = +time[0] % 12 || 12 // Adjust hours
  }
  return time.join('') // return adjusted time or original string
}

export default {
  search,
  isDateExpired,
  convertTime,
}
