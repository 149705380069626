import { createStore } from 'vuex'
import dashboardCountResultsModule from '@/store/dashboardCountResults'
import docqParentDetailsModule from '@/store/docqParentDetails'
import roleManagementModule from '@/store/roleManagement'

export default createStore({
  modules: {
    dashboardCountResults: dashboardCountResultsModule,
    docqParentDetails: docqParentDetailsModule,
    roleManagement: roleManagementModule,
  },
})
